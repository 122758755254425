@import '../../styles/variables';

.search-input {
  padding-left: 40px;
  height: 40px;
  line-height: 40px;
  width: 260px;
  background-image: url('../../images/search.png');
  background-size: 16px 16px;
  background-color: var(--color-offwhite);
  background-repeat: no-repeat;
  background-position: 12px;
  border: 1px solid #d3d3d3;
  border-radius: 2px;

  @media (--small-viewport) {
    font-size: 16px;
    width: 220px;
  }
}

.results-container {
  width: 260px;
  border: 1px solid #d3d3d3;
  border-top: none;
  border-radius: 2px;
  background-color: white;
  position: absolute;

  @media (--small-viewport) {
    width: 220px;
  }
}

.results-label {
  text-transform: uppercase;
  color: var(--color-gray);
  letter-spacing: 1.1px;
  padding: 1em 2em;
}

.result {
  background-color: white;
  padding: 1em 2em;
  color: var(--color-blue);
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.result.highlighted {
  background-color: var(--color-offwhite);
}
