@import '../../styles/variables';

.header {
  display: flex;
  margin-right: var(--gutter);
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  flex-grow: 1;

  &.loggedIn {
    margin-right: 0;
  }

  a {
    text-decoration: none;
  }
}

.header-menu {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-weight: 500;
  letter-spacing: 1px;

  @media (--max-mobile-menu) {
    align-items: flex-start;
    flex-direction: column;
    font-size: 24px;
    line-height: 2.5;
    position: static;
    right: 0;
    top: 0;
    width: 272px;
  }

  @media (min-width: 937px) {
    font-size: 14px;
  }

  .btn-sign-up {
    color: var(--color-white);
  }

  a {
    color: var(--font-color);

    &:global(.active) {
      color: var(--color-blue);
    }
  }

  @media (min-width: 937px) {
    padding-left: var(--gutter);
  }
}

.bottom {
  @media (min-width: 937px) {
    max-width: 20%;
  }
}

.top {
  @media (--max-mobile-menu) {
    align-items: flex-start;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    padding: 1em 1.5em 1em;
    order: 1;

    &.loggedIn {
      padding: 3em 1.5em 1em;
    }
  }

  @media (min-width: 937px) {
    flex-grow: 1;

    .mobile-only {
      display: none;
    }
  }

  a {
    color: inherit;
    display: inline-block;
    @media (min-width: 937px) {
      padding: 1em .5em;
    }
    @media (min-width: 1296px) {
      padding: 1em .75em;
    }
    @media (min-width: 1441px) {
      padding: 1em 1.5em;
    }
  }
}

.bottom {
  @media (--max-mobile-menu) {
    flex-basis: 50%;
    padding: 1em 1.5em;
    order: 2;
  }
}

.cms {
  display: none;

  @media (min-width: 768px) {
    align-self: stretch;
    align-items: center;
    border-left: 1px solid var(--gray-ef);
    display: flex;
    flex-basis: var(--header-height-desktop);
    justify-content: center;
  }

  a {
    color: inherit;
    padding: 1em var(--gutter);
  }

  &.isCms {
    border-left-color: var(--border-color-dark);
  }
}

.options (max-width: 936px) {
  line-height: 2.5;
  font-weight: 500;
  order: 0;
}

.options {
  @media (--max-mobile-menu) {
    font-size: 24px;
    padding: 3em 1.5em 1em;
    align-self: flex-start;

    align-items: flex-start;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;


    a {
      color: inherit;
    }
  }

  a {
    text-decoration: none;
    @media (min-width: 937px) {
      padding: 0.75em 1em;
      margin-left: 11px;
    }
    @media (--tablet-landscape) {
      padding: 0.75em 2.5em;
    }
  }
}
