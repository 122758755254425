@import '../../../styles/variables';

.artwork-count {
  box-sizing: content-box;
  margin: 2.66em auto;
  max-width: var(--inner-width);
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  color: var(--color-gray);
  padding: 0 var(--gutter);

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }
}
