@import '../../styles/variables';

ol {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
         text-decoration: underline;
     }
}

.invoice-list {
    li {
        display: flex;
        border-bottom: 1px solid var(--color-light-gray);
        padding: 25px 0;
        letter-spacing: 1px;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        @media (--small-viewport) {
            flex-direction: column;
        }
    }

    li:first-child {
        border-top: 1px solid var(--color-light-gray);
        margin-top: 30px;
    }

    .invoice-summary {
        display: flex;
        flex-direction: row;

        @media (--small-viewport) {
            flex-direction: column;
        }
    }
}

.date {
    color: var(--color-gray);
}

.amount {
    color: var(--color-gray);
    min-width: 10em;
    text-align: right;

    @media (--small-viewport) {
        text-align: left;
    }
}

.container {
    margin-top: 64px;
}

.message {
    color: var(--color-gray);
    font-size: 16px;
    padding: 1em 0;
}
