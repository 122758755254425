@import '../../styles/variables';

.results {
  display: flex;
  width: auto;
  padding: 0 0 45px;
  margin: 0 auto;
  box-sizing: content-box;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (--mobile-landscape) {
    padding: 0 var(--gutter) 60px;
  }

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter) 60px;
  }
}

.result {
  flex: 0 0 100%;
  box-sizing: border-box;
  background-color: var(--color-white);
  margin: 24px 0;

  &:nth-child(1) {
    margin-top: 0;
  }

  @media (--mobile-portrait) {
    flex: 0 0 calc(100%);
  }

  @media (--tablet-portrait) {
    margin: 0;
    flex: 0 0 calc(50% - 30px);
    margin-bottom: 55px;
  }

  @media (--desktop-large) {
    flex: 0 0 calc(33.33% - 40px);

    &:nth-child(3) {
      margin-top: 0;
    }
  }
}

.invisible-child {
  height: 0;

  @media (--mobile-landscape) {
    flex: 0 0 calc(50% - 20px);
  }

  @media (--desktop-large) {
    flex: 0 0 calc(33.33% - 40px);
  }
}
