@import '../../styles/variables';

.list-item {
  list-style: none;
  margin: 10px 0;
  position: relative;
}

.overlay {
  background-color: rgba(220, 220, 220, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 15%;
}

.container {
  background-color: var(--color-white);
  box-shadow: 0 0 4px 0 rgba(220,220,220,0.5);
  display: flex;
  line-height: 1.4;
}

.image {
  border-right: 1px solid #F0F0F0;
  flex: 0 0 15%;
  padding: 20px;
  text-align: center;
  width: 15%;
  pointer-events: none;

  img {
    max-height: 80px;
    max-width: 100%;
  }

  :global(.react-player__shadow)  {
    background: none !important;
  }
}

.info {
  display: flex;
  flex: 1 1 auto;
  padding: 20px;
}

.title, .year, .medium, .dimensions, .status {
  color: var(--color-gray);
  display: flex;
  flex-direction: column;
  flex: 1 1 20%;
  justify-content: center;
  padding-left: 20px;
}

.status {
  width: 105px;

  select {
    background: var(--gray-cf);
    border: 0;
    border-radius: 2px;
    color: var(--color-white);
    padding: .25em 1.5em;
    margin-top: 0;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    height: auto;
    width: 105px;
    align-items: flex-end;

    &.published {
      background: var(--color-blue);
      width: 90px;
    }
  }
}

.actions {
  border-left: 1px solid #F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;

  .button-stack {
    display: flex;
    flex-direction: column;

    button:first-child {
      margin-bottom: 15px;
    }
  }

  button {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 3px;
    color: var(--color-gray);
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    padding: .5em 1em;
    text-transform: uppercase;

    &:hover {
      background-color: var(--color-gray);
      color: var(--color-white);
      cursor: pointer;
    }
  }

  select {
    margin: 0 0 0 20px;
  }
}
