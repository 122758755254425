@import '../../../styles/variables';

.profile-img {
  width: 100%;
  margin: 2em 0;
  text-indent: -9999px;
}

.body {
  position: relative;
}

.copy {
  color: var(--color-gray);
  font-size: 13px;
}
