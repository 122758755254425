@import '../../styles/variables';

.container {
  border-bottom: 1px solid var(--gray-ef);
  border-top: 1px solid var(--gray-ef);

  .inner-container {
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--inner-width);
    padding: 0 var(--gutter);

    @media (--tablet-portrait) {
      align-items: center;
      height: var(--header-height-desktop);
    }

    @media (--small-viewport) {
      padding: 2em var(--gutter);
    }

    @media (--tablet-landscape) {
      padding: 0 var(--gracious-gutter);
    }
  }

  .link-container {
    button {
      cursor: pointer;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 14px;
      line-height: 19px;
      margin-right: 3em;
      border: none;
      background: transparent;

      @media (--small-viewport) {
        display: block;
        margin-bottom: 1.5em;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:hover, &:active, &:focus, &:global(.active) {
        color: var(--color-dark-gray);
      }

    }

    @media (--small-viewport) {
      width: 50%;
      display:flex;
      flex-direction: column;
    }
  }

}
