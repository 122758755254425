@import '../../styles/variables';

.container {
  min-height: 600px;
  padding: 6.5em var(--gutter);
  background-color: var(--color-offwhite);

  @media (--tablet-landscape) {
    padding-left: var(--gracious-gutter);
    padding-right: var(--gracious-gutter);
  }
}
