@import '../../styles/variables';

.about {
  a {
    color: var(--gray-85);

    &:hover {
      color: var(--color-dark-gray);
    }
  }

  p {
    color: var(--color-dark-gray);
    font-size: 15px;
  }

  h2 {
    font-size: 24px;
  }
}

.intro {
  box-sizing: content-box;
  margin: auto;
  max-width: var(--inner-width);
  padding: 0 var(--gutter);
  text-align: center;

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }

  h1 {
    font-size: 32px;
    margin: 64px 0 1em;
  }
}
.intro-content {
  line-height: 2;
  margin: 1em auto 6em;
  max-width: 564px;
}


.sections {
  background: var(--color-offwhite);
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-top: 0;
  }
}

.section {
  display: flex;
  text-align: center;

  &:nth-child(even) {
    background-color: white;
  }

  &:nth-child(even) .inner {
    @media (--mobile-landscape) {
      flex-direction: row;
    }
  }

  &:nth-child(odd) .inner {
    @media (--mobile-landscape) {
      flex-direction: row-reverse;
    }
  }
}

.section .inner {
  box-sizing: content-box;
  margin: var(--gutter) auto;
  max-width: 734px;
  padding: 0 var(--gutter);

  @media (--tablet-landscape) {
    margin: auto;
    max-width: var(--inner-width);
    padding: 0 var(--gracious-gutter);
  }

  display: flex;
  justify-content: space-between;
  text-align: center;

  @media (--small-viewport) {
    flex-direction: column;
  }

  @media (--tablet-portrait) {
    min-height: 475px;
  }
}

.column {
  align-items: center;
  display: flex;
  justify-content: center;
}

.text {
  font-size: 18px;
  line-height: 2;
  @media (--small-viewport) {
    padding: 0 var(--gutter);
  }

  @media (--mobile-landscape) {
    flex-basis: 375px;
    flex-shrink: 0;
  }
}
.image {
  @media (--tablet-portrait) {
    flex-basis: calc(100% - 375px - var(--gutter) - var(--gutter));
    flex-grow: 0;
    margin: 4em 0;
  }
}

.image img {
  border-radius: 2px;
  border: 1px solid var(--border-color);
  display: block;
  width: 100%;

  @media (--tablet-portrait) {
    margin: 2em auto;
  }
}

.content {
  margin: auto;
  max-width: 375px;
}

.spinner {
  margin-top: 100px;
}