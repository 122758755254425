.sticky-bottom {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 2;
  /* This is so that the overlay div doesn't prevent the user from interacting with the rest of the page */
  pointer-events: none;
}

.notice {
  background-color: var(--color-light-gray);
  width: 100%;
  padding: 2em;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  /* And this is to that at the same time the user can interact with the actual notification at the bottom of the screen */
  pointer-events: all;
}

.notice-content {
  color: var(--color-black);
  letter-spacing: .8px;
  line-height: 2;
  margin: 0;

  a {
    color: var(--color-blue);
  }
}

.close-notice {
  border: none;
  background-color: transparent;
  color: var(--color-black);
  font-size: 24px;
  padding-top: 0;
  padding-bottom: 3px;

  &:hover {
    cursor: pointer;
  }
}
