@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: center;

  @media (--desktop-small) {
    flex-direction: column;
  }
}

.homepage-artwork-carousel {
  border-top: 1px solid var(--color-light-gray);
}

.homepage-guest-curators-carousel {
  border-top: 1px solid var(--color-light-gray);
  padding: 80px 0 0;
}

.homepage-dialogues-carousel {
  background-color: var(--color-offwhite);
  padding: 80px 0 0;
}

.intro,
.open-call {
  flex: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  .copy {
    h1 {
      margin: 0 auto 2rem;
    }

    p,
    a {
      margin: 2rem auto;
    }
  }
}

.intro {
  padding: 80px 0;

  .copy {
    margin: 0 auto 1rem;
  }
}

.open-call {
  padding: 3rem 0;
  background-color: var(--color-blue);

  .copy {
    width: 615px;

    @media (--small-viewport) {
      width: 90%;
    }

    h1,
    p,
    a {
      color: var(--color-white);
    }

    p,
    a {
      font-size: 16px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;

  @media (--tablet-portrait) {
    flex-direction: row;
    justify-content: space-between;
    min-width: 540px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--desktop-xx-large) {
    padding-bottom: 0;
  }
}

.carousel {
  flex: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--color-light-gray);
}

.copy {
  font-family: var(--font-family-sans);
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  margin: 2rem auto;
  width: 300px;

  p {
    color: var(--color-dark-gray);
  }

  h1 {
    font-size: 40px;
    line-height: 44px;
    font-weight: bold;
    color: var(--black);
  }

  @media (--mobile-portrait) {
    width: 326px;
  }

  @media (--small-viewport) {

    h1 {
      font-size: 32px;
      line-height: 30px;
    }
  }

  @media (--tablet-portrait) {
    margin: 4rem auto 2rem;
    width: 600px;

    p {
      font-size: 15px;
      width: 80%;
    }
  }

  @media (--tablet-landscape) {
    width: 782px;
  }

  @media (--desktop-small) {
    margin: 3rem auto;
    padding-top: 0;
    width: 600px;

    h1 {

      width: auto;
    }
    p {
      font-size: 14px;
      width: auto;
    }
  }

  @media (--desktop-x-large) {
    p {
      font-size: 15px;
    }
  }

  @media (--desktop-xx-large) {
    width: 420px;
    padding-top: 8rem;
    h1 {
      font-size: 29px;
    }
    p {
      font-size: 17px;
    }
  }
}

.hero-button {
  color: var(--color-white);
  display: flex;
  font-family: var(--font-family-heading);
  font-weight: 500;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  min-width: 240px;

  padding: 1em 3em;
  text-decoration: none;
  border-radius: 2px;
  display: inline-block;

  &:last-child {
    margin-bottom: 0;
  }

  text-decoration: none;
  border-radius: 3px;

  font-size: 16px;
  height: 64px;
  padding: 18px;

  width: 150px;
  transition: 200ms opacity ease-in-out;

  &:hover,
  &:active,
  &:focus {
    opacity: .8;
  }

  @media (--small-viewport) {
    &:first-child {
      margin-bottom: 24px;
    }
  }
}

.button-head-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

nowrap {
  white-space: nowrap;
}

.label {
  flex-grow: 1;
}

.arrow {
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva,
    Verdana, sans-serif;
  font-weight: 500;
}

.section-title {
  color: var(--color-black);
  font-size: 40px;
  line-height: 48px;
  margin-top: 0;
}

:global(.swiper-container-horizontal > .swiper-pagination-bullets) {
  bottom: 30px !important; 

  :global(.swiper-pagination-bullet) {
    height: 6px !important;;
    width: 6px !important;;
  }
}

:global(.swiper-pagination) {
  display: none;

  @media (--small-viewport) {
    display: block;
  }
}

.banner-slide {
  display: block;
  height: calc(100vh - 80px);
  width: 100vw;
  position: relative;
  padding: 48px 64px;
  text-decoration: none;

  h1 {
    z-index: 1;
    position: relative;
    color: var(--color-white);
    width: 60%;
    max-width: 560px;
    font-size: 36px;
    line-height: 48px;
  }

  @media (--small-viewport) {
    padding: 24px;

    h1 {
      max-width: 75%;
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.banner-slide-background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

  }
}

.header-link {
  text-decoration: none;
}
