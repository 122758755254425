@import '../../styles/variables';

.container.wide {
  display: flex;
  margin: 1em 0;
  color: var(--color-dark-gray);

  label {
    width: 35%;
    text-align: right;
    letter-spacing: 1px;
    padding-right: 30px;
    line-height: 40px;
    font-size: 12px;
  }

  .select-wrapper {
    margin-top: 0;
    width: 65%;

  }

  select {
    height: 40px;
    margin-top: 0;
    font-size: 16px;
  }
}
