@import '../../../styles/variables';

.container {
  border-width: var(--header-height);
  border-width: var(--footer-height);
  margin: var(--header-height) 0 0;
  min-height: calc(100vh - var(--header-height) - var(--footer-height) );
  overflow: hidden;
  padding: 0;

  @media (--tablet-portrait) {
    margin: var(--header-height-desktop) 0 0;
    min-height: calc(100vh - var(--header-height-desktop) - var(--footer-height-desktop) );
  }
}
