@import '../../styles/variables';

.default {
  position: relative;

  .overlay {
    display: none;

    @media (--small-viewport) {
      display: block;
    }
  }
}

.is-open {
  .overlay {
    display: block;
  }
}

.container {
  position: relative;
}

.label {
  box-sizing: border-box;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 3em 0 1.5em;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  color: var(--color-dark-gray);
  letter-spacing: 1px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  word-break: break-all;

  svg {
    position: absolute;
    right: 16px;
    top: 18px;
  }
  
  .active & {
    background-color: var(--color-offwhite);

    &:after {
      opacity: 0;
    }
  }

  @media (--small-viewport) {
    display: none;
  }
}

.reset-button {
  background-color: var(--color-offwhite);
  border: 1px solid var(--color-blue);
  border-radius: 15px;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;

  &:before, &:after {
    background-color: var(--color-blue);
    content: '';
    display: block;
    left: 50%;
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 12px;
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &:hover {
    background-color: var(--color-blue);

    &:before, &:after {
      background-color: var(--color-white);
    }
  }
}

.close-button {
  position: absolute;
  bottom: var(--gutter);
  left: var(--gutter);
  border: 0;
  background-color: transparent;
  padding: 0;
  color: var(--color-gray);
  cursor: pointer;

  font-family: var(--font-family-sans);
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: var(--color-dark-gray);
  }

  @media (--small-viewport) {
    display: none;
  }
}

.apply-button {
  border: 0;
  color: var(--color-blue);
  cursor: pointer;
  position: absolute;
  right: var(--gutter);
  bottom: var(--gutter);
  padding: 0;
  background-color: transparent;
  font-family: var(--font-family-sans);
  font-weight: 500;
  font-size: 14px;
}

.overlay {
  position: absolute;
  background-color: var(--color-white);
  border-radius: 2px;
  padding: var(--gutter) var(--gutter) 4.5em;
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  top: calc(100% + 8px);
  z-index: 1;

  @media (--small-viewport) {
    position: relative;
    box-shadow: none;
    top: auto;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 1px solid var(--gray-ef);
    z-index: inherit;
  }
}

.right .overlay {
  right: 0;
}
