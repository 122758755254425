* {
  box-sizing: border-box;
}

:root {
  color: var(--font-color); /* ported */
  font-size: 12px; /* ported */
  font-family: var(--font-family-sans); /* ported */
  line-height: 2; /* ported */
  -webkit-font-smoothing: antialiased; /* ported */
  -moz-osx-font-smoothing: grayscale; /* ported */
  -webkit-text-size-adjust: 100%; /* ported */
  -ms-text-size-adjust: 100%; /* ported */
}

:global(html.no-scroll) {
  height: 100%;
  overflow: hidden;
}

:global(html.no-scroll body) {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
}

h1 {
  color: var(--color-dark-gray);
  font: 500 30px/normal var(--font-family-heading);
}

h2 {
  color: var(--color-dark-gray);
  font: 500 22px/normal var(--font-family-heading);
}

h3 {
  color: var(--font-color);
  font: bold 22px/1 Roboto;
}

h4 {
  color: var(--font-color);
  font: 700 14px/normal Roboto;
}

h5 {
  color: var(--font-color);
  font: 300 28px/normal Roboto;
}

h6 {
  color: var(--color-gray);
  font: 500 12px/normal Roboto;
  text-transform: uppercase;
}

p {
  color: var(--gray-85);
  font: normal 14px/2 Roboto, sans-serif;
}

.white {
  color: var(--color-white);
}

.small {
  font: normal 12px/2 Roboto, sans-serif;
}

.helper-text {
  color: var(--color-gray);
}

.container {
  background: hsla(0, 0%, 0%, .2);
  padding: 0 var(--gracious-gutter);
}
