@import '../../styles/variables';

.container {
  position: fixed;
  z-index: 2;
  color: var(--color-white);
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
}

.inner {
  padding: 0 30px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 0;
  border: none;
  background-color: var(--color-white);
}
