@import '../../styles/variables';

.container.wide {
  display: flex;
  margin: 1em 0;
  color: var(--color-dark-gray);

  label {
    letter-spacing: 1px;
    line-height: 40px;
    margin: 0;
    padding-right: 30px;
    text-align: right;
    width: 35%;
    font-size: 12px;
  }

  .select-wrapper {
    width: 65%;

    select {
      margin-top: 0;
    }
  }
}

.container {
  color: var(--color-dark-gray);

  &.tall {
    .select-wrapper select {
      height: 3em;
    }
  }

  label {
    letter-spacing: 1px;
    line-height: 2;
  }

  .select-wrapper {
    select {
      margin-top: 0;
      height: 40px;
      font-size: 16px;

      @media (--small-viewport) {
        height: 3em;
        font-size: 16px;
      }
    }
  }
}
