@import '../../../styles/variables';

.container {
  box-sizing: content-box;
  margin: 0 auto 100px;
  max-width: 412px;
  padding: 0 var(--gracious-gutter);
  text-align: center;

  @media (--small-viewport) {
    padding: 0 var(--gutter);
  }
}
