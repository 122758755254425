@import '../../../styles/variables';


:global(html.locked) {
  overflow: hidden;
}

.wrapper {
  align-items: stretch;
  background-color: var(--color-white);
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 320ms ease-in-out;
  z-index: 3;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.container {
  align-items: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 150px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

button.close-button {
  position: absolute;
  top: 30px;
  right: 24px;
  display: block;
  background-color: transparent;
  height: 20px;
  width: 20px;
  border: 0;
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2px;
    width: 24px;
    background-color: var(--color-dark-gray);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}

button.previous-button,
button.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  border: 0;
  font-size: 14px;
  background-color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-family-sans);
  color: var(--color-gray);

  &:hover {
    color: var(--font-color);
  }
}

button.previous-button {
  left: 30px;
}

button.next-button {
  right: 30px;
}

.content {
  display: flex;
  width: 100%;
  max-width: 1300px;
}

@media (--small-viewport) {
  .container {
    padding: 75px var(--gutter);
    display: block;
  }

  button.previous-button,
  button.next-button {
    bottom: 0;
    height: 70px;
    top: auto;
    transform: none;
    width: 50%;
  }

  button.previous-button {
    left: 0;
  }

  button.next-button {
    right: 0;
  }
}
