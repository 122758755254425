@import '../../styles/variables';

.static-hero {
  padding: 100px 140px;
  border-bottom: 1px solid var(--gray-ef);
  text-align: center;

  h1 {
    font-size: 56px;
    margin: 0 0 .5em;
    line-height: 1;
    color: var(--font-color);
  }

  p {
    font-size: 18px;
    margin: 0;
    line-height: 1.5;
    color: var(--color-dark-gray);
    @media (--small-viewport) {
      margin: auto;
    }
  }

  @media (--small-viewport) {
    padding: 50px 10%;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 40px;
    }

    p {
      max-width: 80%;
      font-size: 18px;
    }
  }
}
