@import '../../styles/variables';

.field {
  display: flex;
  border-bottom: 1px solid var(--color-light-gray);
  padding: 25px 0;
  letter-spacing: 1px;
  flex-wrap: wrap;
}

.label {
  width: 40%;
  color: var(--color-dark-gray);
}
.student-note {
  margin-top: 1em;
  color: var(--color-dark-gray);
}

.info {
  width: 60%;
  color: var(--color-gray);
}
