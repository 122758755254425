@import '../../styles/variables';

.container {
  text-align: left;
  width: 100%;

  label {
    font-size: 12px;
    font-family: var(--font-family-sans);
    letter-spacing: 1px;
    color: var(--color-dark-gray);
    line-height: 2;
  }
}

.input-wrapper {
  position: relative;
  width: 100%;

  input {
    border: 1px solid var(--border-color);
    font-size: 12px;
    height: 48px;
    padding: 0 1em 0 3.5em;
    width: 100%;
    font-family: var(--font-family-sans);
    line-height: inherit;
    appearance: none;
    color: var(--color-dark-gray);
    letter-spacing: 1px;

    &:focus {
      border-color: var(--color-blue);
    }
    @media (--small-viewport) {
      font-size: 16px;
    }
  }

  &:after {
    content: '';
    height: 18px;
    width: 19px;
    position: absolute;
    left: 15px;
    background-image: url('../../images/search.png');
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
  }
}

.options {
  background-color: white;
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--color-light-gray);
  max-height: 302px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &.active {
    display: block;
  }
}

.option {
  display: block;
  padding: 1em 1em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
  color: var(--color-dark-gray);
  border: 0;
  background: transparent;
  text-align: left;

  &:hover {
    background-color: var(--color-offwhite);
  }

  @media (--small-viewport) {
    font-size: 16px;
  }
}

.reset-button {
  border-radius: 15px;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  border: none;
  background: transparent;

  &:before, &:after {
    background-color: var(--color-blue);
    content: '';
    display: block;
    left: 50%;
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 12px;
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &:hover {
    background-color: var(--color-blue);

    &:before, &:after {
      background-color: var(--color-white);
    }
  }
}