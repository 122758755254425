@import '../../../styles/variables';

.container {
  padding-bottom: 45px;
}

.no-results {
  color: var(--color-gray);
  text-align: center;
}
