@import '../../../styles/variables';

.content-container {
  background: var(--color-offwhite);
  min-height: calc(100vh - 396px);
  padding-bottom: 60px;
}

.inner-container {
  max-width: 620px;
  margin: 0 auto;
  padding: 64px 0;

  @media (--small-viewport) {
    padding-top: 30px;
    max-width: 90%;
  }
}
