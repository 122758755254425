@import '../../styles/variables';

.container {
  background-color: var(--color-white);
}

.image-wrapper {
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 384px; 
  padding: 0;

  a {
    max-height: 100%;
    display: flex;
  }

  img {
    max-height: 100%;  /* corresponds with :medium sizing */
    max-width: 100%;

    @supports (object-fit: contain) {
      object-fit: contain;
      width: 100%;
    }

    @media (--mobile-landscape) {
      max-height: 100%;
    }

    /* TODO Can't we avoid duplicating this padding here? */
    padding: 48px;

    @media (--small-viewport) {
      padding: 24px;
    }
  }

  .on-a-slider {
    @media (--small-viewport) {
      padding: 24px 24px 50px 24px;
    }
  }

  .video {
    display: flex;
    align-items: center;
    width: 100%;
    pointer-events: none;

    :global(.react-player__shadow)  {
      background: none !important;
    }

    /* TODO Can't we avoid duplicating this padding here? */
    padding: 48px;

    @media (--small-viewport) {
      padding: 24px;
    }
  }

  :global(.slider) {
    display: flex !important;
    flex-direction: column;
  }

  :global(.slider-list), :global(.slider-slide), :global(.slider-slide) > div {
    height: 100% !important;
  }

  :global(.slider-control-centerright) {
    right: 2% !important;

    @media (--small-viewport) {
      right: 0 !important;
    }

    @media (hover: none) {
      display: none;
    }
  }
  :global(.slider-control-centerleft) {
    left: 2% !important;
    
    @media (--small-viewport) {
      left: 0 !important;
    }

    @media (hover: none) {
      display: none;
    }
  }

  :global(.slider-control-bottomcenter) {
    /* Reset original styles */
    position: static !important;
    bottom: 0px !important;
    left: 0% !important;
    transform: none !important;

    /* Here are the custom ones */
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  :global(.slider-control-centerright), :global(.slider-control-centerleft) {
    button {
      background: transparent;
      font-size: 30px;
      border: none;
      color: var(--color-gray);
      cursor: pointer;
    }
  }
}

.video {
  width: 100%;
}

.artwork-card .image-wrapper {
  border: none;
  border-bottom: 2px solid var(--color-offwhite);
  background: transparent;
  width: 100%;
}

.artist-card .image-wrapper {
  border-top: 2px solid var(--color-offwhite);
}

.content {
  padding: 40px 45px;

  @media (--small-viewport) {
    padding: 32px 24px;
  }


  h4.artist-name {
    line-height: 1;
    margin-top: 0;
    margin-bottom: 8px;

    a {
      color: var(--font-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .lives-works {
    color: var(--color-dark-gray);
    font-size: 13px;
    line-height: 1.8;
    margin-bottom: 15px;
    line-height: 1.4;
  }

  .extra-info {
    color: var(--color-dark-gray);
  }

  .school {
    line-height: 1.4;
    margin-bottom: .4em;
  }

  .formats {
    line-height: 1.4;
  }

  .title {
    font-style: italic;
  }
  .medium, .title-wrapper {
    line-height: 1.4;
    margin-bottom: .4em;
  }
  .medium, .dimensions, .title-wrapper {
    color: #999;
  }
  .dimensions {
    line-height: 1.4;
  }
}
