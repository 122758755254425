@import '../../styles/variables';

.pointer {
  cursor: pointer;
}

.btn-cancel {
  margin-top: 64px;
  padding: 1em;
}
