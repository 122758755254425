@import '../../styles/variables';

.overlay {
  position: fixed;
  z-index: 2;
  color: var(--color-white);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(30, 30, 30, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @media (--small-viewport) {
    align-items: flex-start;
  }
}

.container {
  position: relative;
  padding: 0 40px 40px;
  background: var(--color-white);
  border-radius: 2px;

  @media (--small-viewport) {
    padding: 50px;;
    width: 100%;
    height: 100vh;
  }
}

.close-button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 34px;
  position: absolute;
  top: 0;
  right: 0;
}
