@import '../../../styles/variables';

.container {
    font-size: 12px;

    h1 {
        letter-spacing: 1px;
        color: var(--color-white);
    }

    p {
        margin-bottom: 2em;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
        color: var(--color-white);
    }

    a {
        width: 230px;
        text-align: center;
        display: inline-block;
        font-size: 12px;
        margin-top: 30px;
        padding: 1em 2em;
        text-decoration: none;
        letter-spacing: 1.25px;
    }
}
