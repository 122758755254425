@import "../../styles/variables";

.dialogue-card {
  width: 80%;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;

  @media (--small-viewport) {
    flex-direction: column-reverse;
  }

  div {
    margin: 0 3rem;
  }

  .dialogue-image {
    width: 60%;
    max-height: 100%;

    @media (--small-viewport) {
      width: 100%;
      margin-bottom: 56px;
    }

    img {
      width: 100%;
      max-height: 60vh;
      object-fit: contain;
    }
  }

  .caption {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    text-align: left;

    @media (--tablet-portrait) {
      width: 30%;
    }

    @media (--small-viewport) {
      width: 80%;
      justify-content: center;
      align-items: center;
    }

    .interview-name, .interview-details p, .interview-details h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29.5px;
      font-family: var(--font-family-heading);
      letter-spacing: .6px;
      margin: 0;
    } 

    .interview-name {
      @media (--small-viewport) {
        text-align: center;
      }
    }

    .interview-details {
      margin: 0;
      margin-bottom: 30px;

      p {
        color: var(--color-gray);
      }
    }

    .interview-link a {
      color: var(--color-blue);
      font-size: 16px;
      line-height: 26px;

      &:visited {
        color: var(--color-blue);
      }
    }

    h1 {
      a {
        color: var(--font-color);
        text-decoration: none;

        &:visited {
          color: var(--font-color);
        }
      }
    }

    p {
      text-align: left;
      font-size: 1.5rem;
      margin: 0.3rem 0;
      color: var(--font-color);

      @media (--small-viewport) {
        text-align: center;
      }

      a {
        font-size: 1.3rem;
      }
    }
  }
}
