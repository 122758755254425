@import '../../styles/variables';

.privacy {
  box-sizing: content-box;
  color: var(--color-dark-gray);
  font: normal 14px/36px Roboto;
  margin: 1em auto 3em;
  max-width: 700px;
  padding: 0 var(--gutter);

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }

  p {
    color: var(--color-dark-gray);
    font-size: 15px;
  }
}

.intro {
  margin: auto;
}

.intro h1 {
  margin: 64px 0 1em;
  font-size: 32px;
}

.content {
  font: 16px/2 Roboto;
  margin: auto;

  a {
    color: inherit;
  }
}

.sections {
  counter-reset: item;
  margin: auto;
  padding-left: 0;
}

.title {
  display: inline-block;
  font-weight: bold;
}

li.section {
  display: block;
  margin: 1em 0;

  &::before {
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: bold;
  }
}
