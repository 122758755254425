@import '../../styles/variables';

.guests {

  .content-wrapper {
    border-bottom: 1px solid var(--gray-ef);
    padding: 40px var(--gutter);

    @media (--tablet-portrait) {
      padding: 100px var(--gutter);
    }

    @media (--desktop-large) {
      padding: 100px var(--gracious-gutter);
    }

    h2 {
      color: var(--color-dark-gray);
      font-family: var(--font-family-sans);
      font-weight: 300;
      font-size: 24px;
      line-height: normal;
      margin-bottom: 5px;
      max-width: 10em;

      @media (--tablet-portrait) {
        font-size: 32px;
        margin: 0;
        max-width: none;
      }
    }

    .content {
      color: var(--gray-85);
      font-size: 14px;
      line-height: 1.6;
      max-width: 16em;

      @media (--tablet-portrait) {
        max-width: none;
      }

      @media (min-width: 501px) {
        font-size: 18px;
        line-height: 1.5;
      }

      p {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}

.list-wrapper {
  margin-top: 80px;

  @media (--small-viewport) {
    margin-top: 50px;
  }
}

.curator-select {
  appearance: none;
  line-height: normal;
  position: relative;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNDg1MzIgNC4yNDI2NEw1LjI0MjY4IDguNDg1MjhMMS4wMDAwNCA0LjI0MjY0IiBzdHJva2U9IiM0QTkwRTIiLz4KPC9zdmc+Cg==");
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  display: none;
  margin-top: 0;

  @media (--small-viewport) {
    display: block;
  }
}


.curator-list {
  column-count: 4;

  @media (--small-viewport) {
    display: none;
  }

  a {
    color: var(--color-black);
    font-size: 14px;
    line-height: 24px;
    display: block;
    text-decoration: none;

    &:hover, &:active, &:focus {
      color: var(--color-black);
    }
  }
}

.spinner {
  margin-top: 100px;
}
