select {
  appearance: none;
  background-color: transparent;
  background-image: var(--background-image-down-arrow);
  background-position: right 1em center;
  background-repeat: no-repeat;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  cursor: pointer;
  display: block;
  font: inherit;
  height: 4em;
  margin-top: 1em;
  padding: 0 2.5em 0 1em;
  width: 100%;
  font-family: var(--font-family-sans);
  letter-spacing: 1px;
}

label + select {
  margin-top: 0;
}

select:focus {
  border-color: var(--color-blue);
}

select:required:invalid {
  color: var(--color-gray);
}

option[value=""][disabled] {
  display: none;
}

option {
  color: black;
}

/* ported */
input:focus {
  border-color: var(--color-blue);
}

textarea:focus {
  border-color: var(--color-blue);
}

input[type="submit"],
button[type="submit"] {
  border: none;
  box-shadow: 0 0 0 #888888;
  cursor: pointer;
  display: inline-block;
  height: 48px;
  margin: 1em 0;
  text-align: center;
  text-decoration: none;
}

label {
  display: inline-block;
  &:empty {
    display: none;
  }
}

label[for] {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
  appearance: none;
  background-color: var(--color-offwhite);
  border: 1px solid var(--color-dark-gray);
  border-radius: 50%;
  width: 17px;
  height: 17px;
}

input[type="checkbox"]:checked {
  background-color: var(--color-blue);
  background-image: url("data:image/svg+xml,<svg width='7' height='7' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg'><path d='M6.913 1L2.22 5.643 0 3.447' stroke='#FFF' fill='none' fill-rule='evenodd'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 7px;
}
