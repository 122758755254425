.school-form {
  padding-top: 2rem;

  .heading {
    padding-left: 35%;
  }

  form {
    display: none;
  }

  .add-school {
    padding-right: 65%;
    text-align: right;
    font-size: 16px;
  }

  .add-school-button {
    padding: 0.5em 1em;
    text-transform: uppercase;
    margin-right: 30px;
  }

  &.is-active {
    .add-school {
      display: none;
    }
    form {
      display: block;
    }
  }
}
