@import '../../styles/variables';

.container {
  box-sizing: content-box;
  max-width: var(--inner-width);
  margin: 38px auto 48px;
  padding: 0 var(--gutter);

  @media (--small-viewport) {
    margin-bottom: 32px;
  }

  @media (--tablet-portrait) {
    margin-top: 48px;
  }

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }

  .name {
    font-size: 22px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .livesAndWorks {
    margin-top: 0;
  }

  p {
    letter-spacing: 1px;
    color: var(--color-dark-gray);
  }

  .hometown, .graduation, .genderPronouns {
    line-height: 1.4;
    margin-bottom: .4em;
    letter-spacing: 1px;
    color: var(--color-dark-gray);
  }

  .graduation span {
    &:after {
      content: ", ";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }

  .website {
    a {
      text-decoration: none;
      color: var(--color-gray);

      &:hover, &:active, &:focus {
        color: var(--color-dark-gray);
      }
    }
  }
}
