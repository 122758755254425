@import '../../styles/variables';

.inputs-container {
  display: flex;
  flex-flow: row wrap;
}

.fake-input-container {
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: 0.5em 0;
  padding: 0;
  text-align: left;

  .label {
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--color-dark-gray);
    line-height: 2;
    margin-top: 1em;
  }

  .input {
    background: var(--color-white);
    border: 1px solid var(--gray-cf);
    border-radius: 2px;
    padding: 1px 1.5em;
    width: 100%;
    font-family: var(--font-family-sans);
    font-size: 12px;
    line-height: 3.7;
    display: flex;
    justify-content: space-between;

    button {
      height: 30px;
      width: 70px;
      padding: 0;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}


