@import '../../../styles/variables';

.container {
  background-color: var(--color-dark-gray);
  color: var(--color-white);
}

.title-container {
  padding: 48px var(--gutter);
  border-top: 1px solid var(--border-color-dark);
  border-bottom: 1px solid var(--border-color-dark);

  @media (--tablet-landscape) {
    padding-left: var(--gracious-gutter);
    padding-right: var(--gracious-gutter);
  }
}

.title {
  margin: 0 auto;
  max-width: 100%;

  .cms {
    font-size: 28px;
    color: var(--color-gray);
  }

  .name {
    font-size: 28px;
    color: var(--color-white);
  }
}

.subheader {
  padding: 0 var(--gutter);
  display: flex;
  justify-content: space-between;

  @media (--tablet-landscape) {
    padding-left: var(--gracious-gutter);
    padding-right: var(--gracious-gutter);
  }
}

ul.links {
  display: flex;
  padding: 0;
  margin: 0;

  li {
    display: block;
    list-style: none;
    padding: 2em 2em 2em 0;

    a {
      letter-spacing: 1px;
      color: var(--color-gray);
      text-decoration: none;
      font-size: 14px;
      line-height: 2;

      &:hover, &:active, &:focus, &:global(.active) {
        color: var(--color-white);
      }
    }
  }
}

.profile-link-container {
  padding: 2em 0;

  a {
    letter-spacing: 1px;
    color: var(--color-gray);
    text-decoration: none;
    font-size: 14px;
    line-height: 2;

    &:hover, &:active, &:focus {
      color: var(--color-white);
    }
  }
}
