@import '../../styles/variables';

.carousel {
  width: 100vw;
  height: 100%;
  position: relative;

  :global(.swiper-container) {
    height: 100%
  }
}

.carousel-card {
  height: 100%;
  padding: 80px 0;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 50%;
  margin: 0 auto;

  @media (--small-viewport) {
    width: 100%;
  }
}

.display-wrapper {
  position: relative;
  width: 100%;
  padding-top: 80%;
}

.display {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.caption {
  display: block;
  text-align: center;
  margin-top: 28px;

  p {
    color: var(--color-dark-gray);
    margin: 0;
    line-height: 1.3;
  }
  .artist {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.8;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .title {
    font-style: italic;
  }
}

.carousel-button {
  position: absolute;
  top: -60px;
  font-size: 2rem;
  border: 0;
  padding: 1rem;
  background: transparent;
  color: var(--color-med-gray);

  &:hover {
    color: var(--color-blue);
  }

  @media (--small-viewport) {
    display: none;
  }
}

:global(.swiper-btn) {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

:global(.swiper-btn-prev) {
  left: 20px;
}

:global(.swiper-btn-next) {
  right: 20px;
}

.controls-dots {
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (--mobile-landscape) {
    /* display: none; */
  }

  .dot-wrapper {
    display: table-cell;
    vertical-align: middle;

    .dot {
      display: inline-block;
      line-height: 24px;
      padding: 2px;
      border: none;
      background: 0;
      font-size: 20px;
      color: var(--color-light-gray);
    }
    .dot.current {
      color: var(--color-blue);
    }
  }
  .dot-wrapper:first-child, li:last-child {
    button {
      font-size: 15px;
    }
    .dot.current {
      font-size: 20px;
    }
  }
}
