@custom-media --small-viewport (max-width: 767px);
@custom-media --mobile-portrait (min-width: 375px);
@custom-media --mobile-landscape (min-width: 480px);
@custom-media --tablet-portrait (min-width: 768px);
@custom-media --tablet-landscape (min-width: 1024px); /* portrait ipad pro*/
@custom-media --desktop-small (min-width: 1280px);
@custom-media --desktop-large (min-width: 1440px);
@custom-media --desktop-x-large (min-width: 1920px);
@custom-media --desktop-xx-large (min-width: 2150px);

@custom-media --max-mobile-menu (max-width: 936px);
@custom-media --min-mobile-menu (min-width: 936px);

:root {
  --color-blue: #4A90E2; /* ported */
  --color-blue-hover: darken(#4A90E2, 5);
  --color-red: #FE5454; /* ported */
  --color-green: #00FF00; /* ported */

  --color-white: #FFFFFF;
  --color-offwhite: hsla(0, 0%, 96%, 1);
  --color-light-gray: #D4D4D4;
  --color-med-gray: #AEAEAE;
  --color-gray: #9B9B9B;
  --color-dark-gray: #494949; /* ported */
  --color-black: #000;
  --font-color: #111111; /* ported */

  --gray-85: #858585;
  --gray-cf: #CFCFCF; /* ported */
  --gray-ef: #EFEFEF;

  --border-color: var(--color-light-gray);
  --border-color-dark: #6A6A6A;

  --gutter: 24px;
  --gutter-lg: 45px;
  --gracious-gutter: 135px; /* ported */

  --header-height: 72px;
  --header-height-desktop: 80px;
  --footer-height: 100px;
  --footer-height-desktop: 100px;
  --body-min-height: calc(100vh - 170px);

  --inner-width: 1390px; /* ported */
  --overlay-inner-width: 450px;
  --form-width: 320px;
  --form-width-lg: 420px;

  --font-family-heading: "GT Eesti Pro", sans-serif; /* ported */
  --font-family-sans: Roboto, sans-serif; /* ported */

  --fast: 400ms;
  --slow: 800ms;

  --box-shadow: 0 2px 10px hsla(0, 0%, 0%, .2);

  --background-image-down-arrow: url("data:image/svg+xml,<svg width='10' height='5' viewBox='0 0 10 5' xmlns='http://www.w3.org/2000/svg'><path d='M9.243 0L5 4.243.757 0' stroke='#4990E2' fill='none' /></svg>")
}
