@import '../../../styles/variables';

.container {
  background-color: var(--color-offwhite);
  min-height: var(--body-min-height);
}

.desktop {
  @media (--small-viewport) {
    display: none;
  }
}

.unpublished-notice {
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--color-red);
  width: 100%;
  color: var(--color-white);
  letter-spacing: .8px;
  line-height: 2;
  padding: 2em 0;

  p {
    width: 80%;
    margin: 0 auto;
    color: var(--color-white);
  }

  @media (--small-viewport) {
    display: none;
  }

  .close-notice {
    border: none;
    background-color: transparent;
    color: var(--color-white);
    font-size: 24px;
    top: 20px;
    right: 1em;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }
}

.mobile {
  padding: 2em;
  letter-spacing: 1px;

  @media (--tablet-portrait) {
    display: none;
  }

  .button {
    margin: 2.6em 0;
  }
}
