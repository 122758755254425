@import '../../styles/variables';

.container {
  color: var(--color-dark-gray);
  margin-bottom: 2em;
}

.subtitle {
  color: inherit;
  font-size: 1em;
  margin: 1em 0 2em;
  font-size: 16px;
}

.copy {
  text-align: left;

  ul {
    line-height: normal;
    padding-left: 1.5em;
  }

  li {
    font-size: 16px;
    margin: 1em 0;
  }

  p {
    text-align: center;
    font-size: 16px;
    line-height: 1.6;
    color: var(--color-dark-gray);
  }
}
