@import '../../styles/variables';

.label {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  h3 {
    font-size: 16px;
  }

  button[type="submit"] {
    margin-top: 7px;
    height: 30px;
    text-transform: uppercase;
    width: 90px;
    padding: 0;

    &.saving {
      background-color: var(--color-gray);
    }
  }
}

.label-and-tooltip {
  display: flex;
  align-items: center;
}
