@import '../../styles/variables';

.video-info {
  display: flex;
  justify-content: space-between;

  span {
    margin-top: -3rem;
  }
}
