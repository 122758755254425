@import '../../styles/variables';

.container {
  list-style: none;
  padding: 1px 0;
  margin: 0;
  flex: 0 0 50%;
  width: 50%;
  display: block;

  button {
    position: relative;
    display: inline-block;
    padding: 5px 5px 5px 30px;
    cursor: pointer;
    color: var(--color-dark-gray);
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    border: 0;
    background: transparent;

    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      border-radius: 20px;
      height: 16px;
      width: 16px;
      border: 1px solid var(--color-dark-gray);
    }
  }

  button.active {
    color: var(--color-blue);

    &:after {
      background-color: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
}
