@import '../../styles/variables';

/* Just above tablet portrait */
@custom-media --tablet-portrait-plus (min-width: 840px);

/* Adjusts to outer container, do not set max here */
.container {
  display: block;
  width: 100%;
  align-items: center;
  margin: auto;

  @media (--tablet-portrait-plus) {
    display: flex;
  }

  @media (--small-viewport) {
    padding-bottom: 70px;
  }
}

.image-wrapper {
  align-self: stretch;
  flex: 0 0 60%;
  position: relative;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    max-height: 600px;

    &.active {
      opacity: 1;
      transition: opacity 250ms;
    }
  }

  @media (--tablet-portrait-plus) {
    height: 100%;
  }

  @media (--tablet-landscape) {
    flex: 0 0 65%;
  }

  .video {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.content {
  flex: 0 0 40%;
  padding: 30px 0 0;
  display: block;
  font-family: var(--font-family-sans);
  font-weight: 400;
  line-height: 1.4;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  h3.artist-name {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;

    a {
      color: var(--font-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .extra-info {
    color: var(--color-dark-gray);
    font-size: 14px;
    line-height: 1.4;

    .field {
      margin-bottom: 10px;
    }
  }

  .title {
    font-style: italic;
  }

  .additional-information {
    color: var(--color-dark-gray);
    line-height: 1.4;
    margin-top: 10px;
  }

  @media (--tablet-portrait-plus) {
    padding: 0 0 0 45px;
  }

  @media (--tablet-landscape) {
    flex: 0 0 35%;
  }
}


.image-thumbs-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  margin-top: 30px;
  width: 120%;
  margin-left: -10%;
  padding: 0 10%;

  @media (--small-viewport) {
    width: 100%;
    margin-left: 0;
  }

  .image-thumb {
    padding: 3px;
    border: 1px solid transparent;
      border-radius: 2px;

    &.active, &:focus, &:hover, &:active {
      border-color: #b4b4b4;
    }

    button {
      border: none;
      padding: 0;
      margin: 0;
      display: block;
      width: 100%;
      padding-bottom: 66%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 2px;
      cursor: pointer;

      &:focus, &:active {
        outline: none;
      }
    }
  }

  :global(.slider-slide.slide-visible:focus), :global(.slider-slider-frame:focus) {
    outline: none;
  }

  :global(.slider-control-centerright) {
    right: -50px !important;
  }

  :global(.slider-control-centerleft) {
    left: -50px !important;
  }

  :global(.slider-control-centerright), :global(.slider-control-centerleft) {
    button {
      background: transparent;
      font-size: 30px;
      border: none;
      color: var(--color-gray);
      cursor: pointer;
    }
  }
}