@import '../../styles/variables';

.container {
  margin-top: 64px;
}

.field {
  display: flex;
  border-bottom: 1px solid var(--color-light-gray);
  padding: 25px 0;
  letter-spacing: 1px;
  flex-wrap: wrap;

  @media (--small-viewport) {
    flex-direction: column;
  }

  .button {
    height: 30px;
    padding: 0 10px;
    margin-top: -3px;
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}

.field.first {
  border-top: 1px solid var(--color-light-gray);
  margin-top: 30px;
}

.label {
  width: 40%;
  color: var(--color-dark-gray);

  @media (--small-viewport) {
    width: auto;
  }
}

.student-note {
  margin-top: 1em;
  color: var(--color-dark-gray);
}

.info {
  width: 60%;
  color: var(--color-gray);
}

.info-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}

.payment-form {
  margin-top: 30px;
  width: 100%;
}

.btn-cancel {
  margin-top: 40px;
  float: right;
  padding: 1em;
}

.error {
  color: var(--color-red);
  display: inline-block;
  letter-spacing: .7px;
}

.note {
  text-align: center;
  font-size: 12px;

  a {
    color: var(--color-blue);

    &:hover, &:active, &:focus, &:visited {
      color: var(--color-blue);
    }
  }
}

.payment-update-notice {
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--color-blue);
  width: 100%;
  color: var(--color-white);
  letter-spacing: .8px;
  line-height: 2;
  padding: 2em 0;

p {
  width: 80%;
  margin: 0 auto;
  color: var(--color-white);
}

@media (--small-viewport) {
  display: none;
}

.close-notice {
  border: none;
  background-color: transparent;
  color: var(--color-white);
  font-size: 24px;
  top: 20px;
  right: 1em;
  position: absolute;

&:hover {
   cursor: pointer;
 }
}
}

.message {
  color: var(--color-gray);
  font-size: 12px;
  letter-spacing: 1px;
  padding: 1em 0;
}
