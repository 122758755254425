@import '../../styles/variables';


.top {
  text-align: center;
  padding: 80px 0;
  width: 400px;
  margin: 0 auto;

    @media (--small-viewport) { 
      padding: 50px 0;
      width: 80%;
    }

  h1 {
    color: var(--font-color);
    margin: 0 auto 30px;
    font-size: 56px;

    @media (--small-viewport) { 
      font-size: 40px;
      width: 270px;
    }
  }

  p {
    color: var(--font-color);
    font-size: 16px;
  }
}

.hero-outer {
  padding: 0 10%;
}
.hero-wrapper {
  padding: 80px 0;
  @media (--small-viewport) {
    padding: 50px 0 0;
  }
}

.hero-top {
  display: flex;

  @media (--max-mobile-menu) {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  p {
    color: var(--font-color);
  }

  .hero-top-details {
    width: 30%;
    padding-right: 3%;
    display: flex;
    flex-direction: column;

    @media (--max-mobile-menu) {
      width: 100%;

      .hero-image-details {
        display: none;
      }
    }

    @media (--small-viewport) {
      text-align: center;
    }

    h2 {
      color: var(--color-blue);
      font-size: 45px;
      line-height: 1;
      margin: 0;
    }

    h3 {
      font-size: 38px;
      margin: 0;
      margin-bottom: 50px;
      font-family: var(--font-family-heading);
      font-weight: 500;

      @media (--small-viewport) {
        font-size: 32px;
        margin-bottom: 30px;
      }
    }

    .content p {
      font-size: 21px;
      font-family: var(--font-family-heading);
      width: 200px;
      line-height: 1.2;

      @media (--small-viewport) {
        width: 100%;
        margin: 1em auto 1.5em;
      }

    }

    .hero-image-details {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  .hero-image-details {
    line-height: 1.5;
  }

  .hero-image-wrapper {
    width: 70%;

    @media (--small-viewport) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

.hero-description {
  margin-top: 90px;
  margin-bottom: 50px;
  padding-left: 30%;

  a {
    color: var(--color-blue);
  }

  @media (--max-mobile-menu) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 20px;

  }

  p {
    color: var(--font-color);
    font-size: 16px;
  }
}
.hero-image-wrapper {
  .hero-image-details {
    display: none;
  }

  @media (--small-viewport) {
    .hero-image-details {
      display: block;
    }
  }
}

.hero-works {
  display:flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 40px 12%;
  color: var(--font-color);

  @media (--small-viewport) {
    flex-direction: column;
    padding: 40px 10% 0;
  }

  .hero-work {
    width: 30%;

    .content {
      margin-top: 16px;
    }

    p {
      line-height: 1.5;
      margin: 0;
      color: var(--font-color);
    }

    img {
      width: 100%;
    }

    @media (--small-viewport) {
      margin-bottom: 60px;
      width: 100%;
    }
  }
}

.jury-wrapper {
  padding: 80px 0 30px;
  color: var(--font-color);

  @media (--small-viewport) {
    padding: 50px 0;
  }
}

.jury {
  max-width: 600px;
  margin: 0 auto;

  p {
    color: var(--font-color);
    font-size: 16px;
  }

  @media (--small-viewport) {
    width: 80%;
  }

}

.jury-title {
  margin-bottom: 90px;
  @media (--small-viewport) {
    margin-bottom: 40px;
  }


  h2 {
    font-size: 45px;
    width: 500px;
    margin: 0 auto 50px;
    text-align: center;
    color: var(--font-color);
    margin-top: 2rem;

    @media (--small-viewport) {
      font-size: 32px;
      margin-bottom: 30px;
      width: 250px;
    }
  }

  p {
    margin: 0 auto;
    text-align: center;
  }
}

.jury-member {
  margin-bottom: 75px;
  @media (--small-viewport) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    grid-template-columns: 3fr 7fr;
  }
}

.shortlist {
  background-color: var(--color-offwhite);
  padding: 100px 0;
  text-align: center;

  @media (--small-viewport) {
    padding: 50px 0;
  }
  p {
    color: var(--font-color);
    margin: 0;
    line-height: 25px;
    font-size: 15px;
  }

  h2 {
    color: var(--font-color);
    padding: 0 12%;
    font-size: 38px;
    margin: 0 0 40px;

    @media (--small-viewport) {
      font-size: 32px;
      margin-bottom: 40px;
      padding: 0 10%;
    }
  }

  .shortlist-members-desktop {
    padding: 0 12%;
    text-align: left;

    @media (--small-viewport) {
      display: none;
    }
  }

  .shortlist-members-mobile {
    display: none;
    text-align: left;

    .shortlist-member-mobile {
      margin-bottom: 60px;

      @media (--small-viewport) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    img {
      width: 100%;
      margin-bottom: 16px;
    }
    @media (--small-viewport) {
      display: block;
      width: 80%;
      margin: 0 auto;
    }

    .lives-works {
      margin-bottom: 20px;
    }
  }

  h4 {
    font-family: var(--font-family-heading);
    font-size: 25px;
    margin-bottom: .75em;
  }

  a {
    text-decoration: none;
  }

  .shortlist-flex-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .shortlist-name, .shortlist-work, .shortlist-work-details {
      width: 30%;
    }

    .shortlist-name {
      margin-bottom: 50px;
    }

    .shortlist-work {
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }
  }
}

.about {
  background-color: var(--color-offwhite);
  padding: 80px 0;

  @media (--small-viewport) {
    padding: 50px 10%;
  }

  h2 {
    font-size: 45px;
    width: 450px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px;
    color: var(--font-color);

    @media (--small-viewport) {
      font-size: 32px;
      width: 250px; 
      margin-bottom: 40px;
    }
  }

  p {
    color: var(--font-color);
    width: 550px;
    margin: 0 auto;
    font-size: 16px;

    @media (--small-viewport) {
      width: 100%;
    }
  }

  a {
    color: var(--gray-85);

    &:hover {
      color: var(--color-dark-gray);
    }
  }

}
