@import '../../../styles/variables';

.container {
  background-color: var(--color-offwhite);
}

.items {
  box-sizing: content-box;
  padding: 30px var(--gutter) 120px;

  @media (--tablet-landscape) {
    padding-left: var(--gracious-gutter);
    padding-right: var(--gracious-gutter);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .artwork-count {
    letter-spacing: 1px;
    color: var(--color-med-gray);
    font-weight: 500;
  }

  a {
    padding: 1.25em 3em;
    border-radius: 2px;
    cursor: pointer;
    background-color: var(--color-blue);
    color: var(--color-white);
    display: inline-block;
    letter-spacing: 1px;

    &:hover {
      background-color: var(--color-blue-hover);
    }
  }
}
