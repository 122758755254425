@import '../../styles/variables';

.guest-card {
  border-bottom: 1px solid var(--gray-ef);
  padding: var(--header-height) var(--gutter);

  @media (--tablet-portrait) {
    padding: var(--header-height) var(--gutter);
  }

  @media (--tablet-landscape) {
    padding: var(--header-height) var(--gutter);
  }

  @media (--desktop-large) {
    padding: var(--header-height) var(--gracious-gutter);
  }
}

.display-card {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: "portrait name" "portrait bio";
  align-items: center;
  margin-bottom: 55px;
  max-width: 1280px;
  grid-gap: 0 30px;

  @media (--desktop-large) {
    gap: 0 50px;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 3rem;
  }

  .artist-portrait {
    grid-area: portrait;
  }

  .artist-portrait img {
    border-radius: 50%;
    display: block;
    object-fit: cover;
    width: 100%;
  }

  h2 {
    font-size: 20px;
    grid-area: name;
    align-self: end;
    margin-bottom: 0px;
    color: black;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    grid-area: bio;
    align-self: start;
    color: var(--color-dark-gray);
    font-size: 15px;

    a {
      color: var(--gray-85);
    }
  }

  &.is-mobile {
    min-height: 88px;
    margin-bottom: 0px;
    grid-template-columns: 4fr 6fr 1fr;
    grid-gap: 0px 20px;
    grid-template-areas: "portraitMobile name toggleIsExpand" "portraitMobile description toggleIsExpand";

    .artist-portrait {
      grid-area: portraitMobile;
      width: 100%;
    }

    h2 {
      font-size: 16px;
      align-self: flex-end;
      margin: 0px;
      line-height: 2;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .toggle-is-expand {
      grid-area: toggleIsExpand;
      font-size: 14px;
      color: var(--color-gray);
      align-self: center;
      background: transparent;
      border: none;
      width: 100%;
      height: 40px;
    }

    svg {
      height: 1em;
    }

    .description {
      grid-area: description;
      margin-top: 0;
      line-height: 1.7;
      font-size: 14px;
      align-self: start;
      color: var(--color-dark-gray);
    }

    p {
      color: var(--color-dark-gray);
      grid-area: bio;

      a {
        color: var(--gray-85);
        text-decoration: underline;
      }
    }
  }

  &.is-expand {
    height: auto;
		grid-template-areas: "portraitMobile name toggleIsExpand" "bio bio bio";
    .toggle-is-expand {
			align-self: center;
    }
    
    h2 {
      align-self: center;
    }
    p {
      align-self: start;
    }
  }
}

.artist-cards {
  @media (--tablet-portrait) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
  }

  @media (--desktop-large) {
		grid-column-gap: 58px;
		grid-row-gap: 15px;
  }
}

.artist-cards {
  > :nth-child(6n + 1) { order: 1 }
  > :nth-child(6n + 2) { order: 4 }
  > :nth-child(6n + 3) { order: 2 }
  > :nth-child(6n + 4) { order: 5 }
  > :nth-child(6n + 5) { order: 3 }
  > :nth-child(6n + 6) { order: 6 }
}

.previous-label {
  font-size: 24px;
  margin-bottom: 50px;
  font-weight: 300;
}

.back-to-guest-selections-cta {
  font-size: 22px;
  font-weight: bold;
  margin: 3em 0 0;
  text-align: center;

  a {
    color: var(--font-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
