@import '../../../styles/variables';

.container {
  text-align: center;
}

.page-button {
  display: inline-block;
  background: transparent;
  width: 3em;
  line-height: 3em;
  padding: 0;
  text-align: center;
  margin: .5em;
  font-family: var(--font-family-sans);
  font-size: 13px;
  border: 1px solid var(--font-color);
  cursor: pointer;

  &.active {
    background-color: var(--font-color);
    color: var(--color-white);
    cursor: default;
  }

  &:hover:not(.active) {
    border-color: var(--color-blue);
    color: var(--color-blue);
  }
}

.page-button.arrow {
  border-color: transparent;

  &:hover:not(.active) {
    border-color: transparent;
    color: var(--color-blue);
  }
}
