@import '../../styles/variables';

.container {
  font-family: var(--font-family-sans);
  font-size: 16px;
  letter-spacing: 1px;
  align-items: center;
  display: flex;
  border-top: 1px solid var(--border-color);
  @media (--small-viewport) {
    line-height: 3;
  }
  @media (--tablet-portrait) {
    font-size: 14px;
    height: var(--footer-height);
  }
  &.isCms {
    background-color: var(--color-offwhite);
  }

  &.fixedFooter {
    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  }
}

.inner {
  box-sizing: content-box;
  color: var(--color-dark-gray);
  display: flex;
  justify-content: space-between;
  padding: 0 var(--gutter);
  width: 100%;

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }
}
@media (--small-viewport) {
  .inner {
    flex-direction: column;
  }
}

.links {
  display: flex;

  @media (--small-viewport) {
    align-items: flex-start;
    flex-direction: column;
    margin: 2em 0;
  }

  a {
    color: var(--color-dark-gray);
    display: inline-block;
    text-decoration: none;

    @media (--tablet-portrait) {
      margin-left: 3em;
    }
  }
}

.copyright {
  @media (--small-viewport) {
    align-items: center;
    display: flex;
    margin: 2em 0;
  }
  @media (--tablet-portrait) {
    order: -1;
  }
}
