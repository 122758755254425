@import '../../styles/variables';

.container {
  margin: 4em auto 0;

  /* Ported */
  .submit {
    margin-top: 2em;
    font-size: 16px;
    height: auto;

    @media (--small-viewport) {
      font-size: 14px;
    }
  }
}

.title {
  text-align: center;
  margin: 0 auto 1em;
  line-height: 1.4;
  font-size: 24px;
}

.error-message {
  color: var(--color-red);
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 1.5em;
  margin-bottom: 1em;
  text-align: center;
}
