@import '../../../styles/variables';

.container {
  background-color: var(--color-white);
  display: flex;
  border: 1px solid var(--gray-ef);
  border-radius: 3px;
}

.image-container {
  width: 300px;
  padding: 40px;
  border-right: 1px solid var(--gray-ef);
}

.form-container {
  flex: 1;
  padding: 40px;
}
