@import '../../styles/variables';

:root {
  --dl-left-margin: 34px;
}

.faq {
  box-sizing: content-box;
  margin: 1em 0 3em;
  max-width: var(--inner-width);
  padding: 0 var(--gutter);

  h2 {
    font-size: 24px;
  }

  @media (--mobile-landscape) {
    display: flex;
  }

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }

  .menu {
    flex-basis: 272px;
    flex-shrink: 0;
    font: bold 12px/2.4166666667 Roboto;
    position: relative;
  }

  .menu a {
    color: var(--color-dark-gray);
    font: 500 18px/2 var(--font-family-heading);
    text-decoration: none;
  }
  .menu a:hover {
    color: black;
  }
  .menuInner {
    display: flex;
    flex-direction: column;
    width: 200px;

    @media (--tablet-portrait) {
      position: fixed;
    }
  }

  .questionGroups {
    flex-grow: 1;
    margin: 60px 0 0;
  }

  .questionGroup {
    padding-top: 1em;

    @media (--tablet-portrait) {
      padding-top: var(--header-height-desktop);
    }
  }

  h1 {
    @media (--tablet-portrait) {
      margin: 64px 0;
    }
  }

  dt {
    font: normal 16px/1 Roboto;
  }

  dt button {
    background-size: 16px;
    display: inline-block;
    padding: 1em 1em 1em 0;
    font-size: 14px;
    border: none;
    background: transparent;
    display: flex;
    line-height: 1.5;
    text-align: left;

    svg {
      margin-right: 16px;
      flex-shrink: 0;
    }
  }

  input[type="checkbox"]:checked ~ dt label {
    background-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path d='M14.93 8L7.86.93.788 8' stroke='#4A90E2' fill='none' /></svg>");
  }

  dd {
    color: var(--color-gray);
    font: normal 14px/2.5714285714 Roboto;
    letter-spacing: 0.07142857143em;
    margin-left: var(--dl-left-margin);
  }

  dd a {
    color: inherit;
    color: var(--gray-85);

    &:hover {
      color: var(--color-dark-gray);
    }
  }

  p {
    color: var(--color-dark-gray);
    font-size:  16px;
  }

}
