@import '../../styles/variables';

.school-select-form {
  text-align: left;
  .container.wide {
    display: flex;
    .cancel-wrapper {
      padding-left: 35%;
      .btn {
        padding: 0.5em 1em;
      }
    }
  }
}
