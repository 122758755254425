@import '../../styles/variables';

.heading-container {
  h1 {
    margin: 0 auto;
    font-family: var(--font-family-sans);
    font-size: 28px;
    text-align: center;
    font-weight: normal;
    letter-spacing: .05em;
    padding: 3em;
    background: var(--color-white);
    border-bottom: 1px solid var(--gray-ef);
    @media (--small-viewport) {
      padding: 1em 0;
    }
  }
}
