@import '../../styles/variables';

.text {
  box-sizing: content-box;
  max-width: var(--inner-width);
  margin: 3em auto;
  font-size: 15px;
  line-height: 1.75;
  padding: 0 var(--gutter);
  white-space: pre-wrap;
  color: var(--color-dark-gray);

  @media (--small-viewport) {
    margin: 2em auto;
  }

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }
}
