@import '../../styles/variables';

.container {
  width: 320px;
  position: absolute;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  box-shadow: 0px 2px 20px 0px rgba(197,197,197,0.5);
  background-color: var(--color-white);
  z-index: 2;
}

.artworks-container {
  border-bottom: 1px solid #d3d3d3;
  padding: 2em;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.hint {
  font-size: 14px;
  margin-bottom: 1em;
}

.wide {
  width: 100%;
}

.cta {
  border: none;
  background-color: var(--color-white);
  font-size: 14px;
  font-family: var(--font-family-sans);
  letter-spacing: .8px;
  font-weight: 500;
  cursor: pointer;
  padding: 24px;

  &.blue {
    color: var(--color-blue);
  }

  &.gray {
    color: var(--color-gray);
  }
}
