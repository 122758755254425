@import '../../styles/variables';

.header-user-menu {
  flex-grow: 1;
  position: relative;
}

.username {
  display: none;

  @media (--min-mobile-menu) {
    background-color: transparent;
    background-image: url("data:image/svg+xml,<svg width='10' height='5' viewBox='0 0 10 5' xmlns='http://www.w3.org/2000/svg'><path d='M9.243 0L5 4.243.757 0' stroke='#4990E2' fill='none' /></svg>");
    background-position: right 0.50em center;
    background-repeat: no-repeat;
    background-size: 1em auto;
    border-color: transparent;
    border-width: calc(2em/11);
    color: var(--color-gray);
    cursor: pointer;
    display: inline-block;
    padding: calc(3em/11) 2em calc(2em/11) calc(6em/11);
    font: inherit;
  }

  &:empty {
    display: none;
  }

  &:hover {
    color: inherit;
  }
}

.dropdown-background {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: var(--header-height);

  @media var(--tablet-portrait) {
    top: var(--header-height-desktop);
  }
}

.dropdown {
  display: flex;
  flex-direction: column;

  a {
    color: var(--color-dark-gray);
    text-decoration: none;
    transition: color var(--slow);
    &:hover {
      color: var(--color-blue);
      transition-duration: var(--fast);
    }
  }
}

@media (--min-mobile-menu) {
  .dropdown {
    align-items: flex-start;
    background: var(--color-white);
    border-radius: 2px;
    border: 1px solid var(--border-color);
    box-shadow: var(--box-shadow);
    font-family: var(--font-family-sans);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 29px;
    padding: 1em 3em;
    position: absolute;
    right: 1em;
    text-transform: uppercase;
    top: 100%;
    width: 175px;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
  }
  .header-user-menu {
    &:hover, &:focus, &:active, &:focus-within {
      .dropdown {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.header-user-menu.active .username {
  color: inherit;
}

.header-user-menu.active + .dropdown-background {
  display: block;
}
