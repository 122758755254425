@import '../../styles/variables';

.prize-announcement-wrapper {
  box-sizing: content-box;
  width: 100%;
  padding: 7rem 0 7rem 0;
  background-color: #328edf;
  text-align: center;
  margin: auto;
}
.prize-announcement {
  max-width: 540px;
  display: inline-block;
  color: var(--color-white);
  font-family: var(--font-family-heading);
  @media (--small-viewport) {
    width: 80%;
  }

  h3 {
    font-family: var(--font-family-heading);
    color: var(--color-white);
    margin-top: 0;
    line-height: 3rem;
  }
}
.prize-announcement--copy p {
  font-family: var(--font-family-heading);
  color: var(--color-white);
  font-size: 16px;
  line-height: 2.5rem;
  font-family: "Roboto";
}

.prize-announcement--copy a {
  color: var(--color-white);

  &:hover, &:active, &:focus {
    color: var(--color-white);
  }
}