@import '../../styles/variables';

.form {
  height: auto;
  min-height: 100%;
  background-color: var(--color-white);
  padding: 3.5em 6em 9em;
  border-radius: 3px;
  border: 1px solid var(--gray-ef);
}

.textbox-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  min-height: 37px;

  .cms-button {
    padding: 0;
    width: 90px;
    height: 40px;

    &.saving {
      background-color: var(--color-gray);
    }
  }
}

.textbox {
  height: 100%;
  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 2;
  border: 1px solid var(--color-blue);
  color: var(--color-dark-gray);
  resize: none;
}
