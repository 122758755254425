@import '../../styles/variables';

.overlay {
  position: fixed;
  z-index: 2;
  color: var(--color-white);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(30, 30, 30, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @media (--small-viewport) {
    align-items: flex-start;
  }
}

.container {
  .open-form {
    margin-left: 16px;
  }

  button {
    padding: 1em;
    line-height: 1em;
    cursor: pointer;
  }

  @media (--small-viewport) {
    width: 50%;
    display: flex;
    flex-direction: column;

    button:first-child {
      margin-bottom: 26px;
    }

    .open-form {
      text-align: center;
      margin-left: 0;
    }
  }
}

.contact-form {
  width: 520px;
  max-width: 90%;
  background: var(--color-white);
  border: 1px solid #FFFCFC;
  border-radius: 3px;
  padding: 30px 40px 40px;
  display: inline-block;
  position: relative;

  @media (--small-viewport) {
    position: relative;
    padding: 15px 20px 20px;
    margin: 20px 0;
  }
}

.contact-form h2 {
  text-align: center;
}

.close-button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 34px;
  position: absolute;
  top: 24px;
  right: 24px;
  @media (--small-viewport) {
    top: 10px;
    right: 10px;
  }
}

.bottom {
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;

  @media (--small-viewport) {
    flex-direction: column;
    margin-top: 1.5em;
  }
}

.message {
  width: 60%;
  padding-right: 3em;
  color: var(--color-dark-gray);
  text-align: left;
  letter-spacing: .7px;
  font-size: 10px;

  @media (--small-viewport) {
    width: 100%;
    padding: 0;
    text-align: left;
    margin-bottom: 1.5em;
  }
}

.btn-submit {
  cursor: pointer;
}

.error {
  margin: 0;
  text-align: center;
  color: var(--color-red);
}
