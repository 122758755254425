@import '../../styles/variables';

.container {
  position: fixed;
  z-index: 2;
  color: var(--color-white);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(30, 30, 30, 0.8);
}

.inner {
  max-width: 100%;
  width: var(--overlay-inner-width);
  margin: 180px auto;
  text-align: center;
}
