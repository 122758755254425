@import '../../styles/variables';

@keyframes spin {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

.spinner {
  animation: spin 1s infinite linear;
  border: 0.125em solid rgba(74, 144, 226, 0.2);
  border-radius: 50%;
  border-top-color: rgb(74, 144, 226);
  box-sizing: border-box;
  font-size: 32px;
  height: 1em;
  width: 1em;
  background-color: transparent;
  margin: 30px auto;
}
