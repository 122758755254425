@import '../../styles/variables';


.guest-artist-card-image {
	display: flex;
	align-items: flex-end;
	img {
		display: block;
		width: 100%;
	}
	@media (max-width: 500px) {
		margin-top: 42px;
	}
}


.title {
	color: var(--font-color);
	font-size: 19px;
	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.copy {
	p {
		color: #494949;
		font-size: 15px;
		letter-spacing: 0.9px;
		line-height: 20px;
	}
}

.content a {
	color: var(--gray-85);
}