@import '../../../styles/variables';

.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--color-white);
  z-index: 3;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  label {
    line-height: 1.5;
  }
}

.container {
  select {
    font-size: 16px;
    height: 3em;
  }
}

.top {
  align-items: center;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 var(--gutter) 0 0;
  @media var(--tablet-portrait) {
    height: var(--header-height-desktop);
  }
}

.actions {
  width: 24rem;
  button.close {
    display: inline-block;
    background-color: var(--color-white);
    appearance: none;
    width: 90px;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
    border: 1px solid var(--border-color);
    color: var(--color-dark-gray);
    padding: 0;
    text-align: center;
    cursor: pointer;
  }

  button[type="submit"] {
    margin: 0 0 0 var(--gutter);
    display: inline-block;
    background-color: var(--color-blue);
    appearance: none;
    width: 90px;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
    border: 1px solid var(--color-blue);
    color: var(--color-white);
    padding: 0;

    &.saving {
      background-color: var(--border-color);
      border-color: var(--border-color);
    }
  }
}

.error-message {
  text-align: center;
  color: red;
  height: 30px;
}

.fields {
  box-sizing: content-box;
  display: flex;
  margin: auto;
  max-width: var(--inner-width);
  padding: 30px var(--gutter);

  @media (--tablet-landscape) {
    padding: 30px var(--gracious-gutter);
  }
}

.image-field {
  flex: 1 0 65%;
  padding-right: 90px;
}

.dropzone {
  border: 2px dashed var(--color-blue);
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 90px var(--gutter);
  color: var(--color-blue);
  font-size: 14px;

  button {
    background-color: var(--color-blue);
    border: 0;
    border-radius: 2px;
    color: var(--color-white);
    padding: .25em 1.5em;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.files {
  display: flex;
  flex-wrap: wrap;
}

.image-file {
  padding: 15px;
  padding-top: 10px;
  margin-bottom: 30px;
  margin-right: 4rem;
  border: solid 1px var(--border-color);
  flex: 0 0 calc(33.33% - 50px);

  img {
    max-width: 100%;
    margin-bottom: -10px;
    height: 100px;
  }

  :global(.react-player__preview) {
    pointer-events: none;
  }

  :global(.react-player__shadow)  {
    background: none !important;
  }
}

.image-wrapper {
  display: flex;
  justify-content: center;
}

.image-info {
  justify-content: space-between;
  text-align: right;
  margin-bottom: 24px;
  display: flex;
  span {
    margin-top: 10px;
  }
}
.image-info-index {
  margin-top: 0px !important;
  color: #444;
}
.video-info {
  margin-top: 1rem;
}

button.remove-button {
  cursor: pointer;
  display: inline-block;
  border: 1px solid var(--color-dark-gray);
  background: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 18px;
  padding-bottom: 3px;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-dark-gray);

  &:hover {
    border-color: var(--color-blue);
    color: var(--color-blue);
  }
}

button.remove-button--replace {
  cursor: pointer;
  display: inline-block;
  border: 1px solid var(--border-color);
  padding: 1rem;
}

.video {
  margin-top: 3.5rem;
}

.guidelines {
  margin-top: 3.5rem;

  p {
    font-size: 14px;
    line-height: 1.75;
    color: var(--color-dark-gray);
    margin: .25em 0;
  }
}

.selected-tags {
  margin: 0;
}

.text-fields {
  flex: 1 0 35%;

  label {
    font-size: 12px;
    line-height: 2;
    color: var(--color-dark-gray);
    margin-top: 2em;
  }
}

.dimensions {
  display: flex;
  justify-content: space-between;

  .dimension {
    flex: 0 0 30%;
    width: 30%;
  }
}

.durations {
  display: flex;
  justify-content: space-between;

  .duration {
    flex: 0 0 30%;
    width: 30%;
  }
}

.video-input {
  border: 1px solid var(--color-blue);
  
  input {
    padding-right: 40px !important;
  }
}

.video-input-wrapper {
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 50px;
    background: transparent;
    border: none;
    font-size: 24px;
  }
}