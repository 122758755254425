@import '../../styles/variables';

.container {
  position: relative;
  display: inline-block;
}

.button {
  padding: 0 10px 0 0;
  color: var(--font-color);
  border: none;
  background: transparent;

  &:visited {
    color: var(--font-color);
  }

  &:hover {
    color: var(--color-blue);
  }
}

.overlay {
  background-color: white;
  color: var(--font-color);
  border-radius: 3px;
  box-shadow: var(--box-shadow);
  position: absolute;
  bottom: 25px;
  left: 50%;
  line-height: 1.6;
  padding: 1.25em 1.5em;
  transform: translateX(-50%);
  text-align: left;
  width: 300px;
  transition: all 350ms;
  opacity: 0;
  pointer-events: none;
  font-size: 16px;

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-50%) translateY(-10px);
    transition: all 250ms;
  }
}
