@import "../../styles/variables";

.guest-curator-card {
  width: 100%;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;

  @media (--small-viewport) {
    flex-direction: column;
  }

  div {
    margin: 0 3rem;
  }

  .guest-curator-image {
    width: 25%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (--small-viewport) {
      width: 80%;
      margin: 0 auto 24px;
    }

    img {
      width: 75%;
      border-radius: 50%;
    }
  }

  .caption {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    text-align: left;

    @media (--tablet-portrait) {
      width: 30%;
    }

    @media (--small-viewport) {
      width: 80%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    h1 {
      margin: 0 0 16px;

      a {
        color: var(--font-color);
        text-decoration: none;

        &:visited {
          color: var(--font-color);
        }
      }
    }

    p {
      text-align: left;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: .6px;
      margin: 0.3rem 0;
      color: var(--font-color);

      @media (--small-viewport) {
        text-align: center;
        font-size: 16px;
      }
    }
  }

  p.curator-details {
    margin: 0 0 16px;
  }

  p.curator-link {
    margin: 0;

    a {
      color: var(--color-blue);
      font-size: 16px;
      line-height: 26px;

      &:visited {
        color: var(--color-blue);
      }
    }
  }
}
