@import '../../styles/variables';


.container {
  width: 820px;
  max-width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (--small-viewport) {
    padding: 0 0 50px;

  }
}

.hero {
  text-align: center;

    @media (--small-viewport) {
      .hero-image {
        margin: 2.5% -10%;
      }
    }

  h1 {
    font-weight: bold;
    font-size: 48px;
    margin-bottom: .25em;
    color: var(--font-color);

    @media (--small-viewport) {
      font-size: 40px;
    }
  }

  img {
    width: 100%;
  }

  p {
    color: var(--font-color);
    font-size: 18px;
    margin: 0;

    &:nth-child(2) {
      margin: 0 auto;
      line-height: 1.5;
    }

    strong {
      display: block;
      font-size: 21px;
      font-weight: bold;
      line-height: 1.5;
      padding-top: 2em;
      max-width: 710px;
      margin: 0 auto;
      text-align: left;

      a {
        color: var(--color-blue);
      }

      @media (--small-viewport) {
        font-size: 18px;
      }
    }
  }
}

.conversation, .pull-quote {
  width: 590px;
  margin: 60px auto 0;
  max-width: 100%;

  p {
    color: var(--font-color);
    font-size: 16px;
  }

  a {
    color: var(--font-color);
    &:hover, &:active, &:focus {
      color: var(--font-color);
    }
  }
}

.pull-quote {
  width: 710px;
  hyphens: auto;

  blockquote {
    margin: 0;
  }

  p {
    font: 700 46px/1 Roboto, Helvetica;
    color: var(--color-dark-gray);

    @media (--small-viewport) {
      font-size: 28px;
    }
  }
}

.image-container {
  width: 800px;
  max-width: 100%;
  margin: 60px auto 0;
  display: flex;
  justify-content: center;

  .image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (--small-viewport) {
    .image-wrapper {
      margin: 0 -10%;
    }
  }

  img {
    max-height: 65vh;
    max-width: 100%;
  }

  p {
    margin: 0;
    line-height: 1.7;
  }
}

.interviewer {
  background-color: var(--color-offwhite);
  padding: 60px 0 50px;
  text-align: center;

  h3 {
    font-family: var(--font-family-heading);
  }

  img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
  }

  p {
    width: 480px;
    max-width: 80%;
    margin: 0 auto 1em;
    color: var(--font-color);
  }

  a {
    color: var(--color-blue);
  }
}

.previous {
  padding: 4em 0 0;

  h3 {
    text-align: center;
    font-weight: normal;
    margin-bottom: -2%;
    margin-top: 0;
    font-size: 28px;
  }
  .btn-transparent {
    background: transparent;
  }

  .view-all {
    padding: 20px 0 100px;
    background-color: var(--color-offwhite);
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    a {
      color: var(--color-dark-gray);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.social-icons-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 40px;
  @media (--small-viewport) {
    margin: 2.5% -10%;
  }


  a {
    display: block;
    margin-left: 20px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}
