@import '../../styles/variables';

.container {
  display: block;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;

  .flex {
    display: flex;
  }

  .img-container {
    width: 50%;
    text-align: left;
  }

  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 80px;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .title {
    padding-left: 1.5em;
    margin: 0;
    font-size: 12px;
    font-family: var(--font-family-sans);
    letter-spacing: .8px;
  }
}

.container .selected img {
  border: 3px solid var(--color-blue);
}

.container + .container {
  margin-top: 30px;
}

