@import '../../../styles/variables';

.container {
  min-height: var(--body-min-height);
  display: flex;
  flex-direction: column;
}

.content {
  background: var(--color-offwhite);
  flex: 1;
}
