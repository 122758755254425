@import '../../styles/variables';

.location-filter {
  @media (--tablet-portrait) {
    width: 250px;
  }

  .location-filter-top {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: var(--color-dark-gray);
  }

  .location-dropdown {
    margin-left: 1em;
    @media (--tablet-portrait) {
      width: 100px;
    }

  }
}


.location-filter-bottom {
  margin: 1em 0;

  input {
    font-size: 14px;

    @media (--small-viewport) {
      font-size: 16px;
    }
  }
}

.mobile-label {
  display: none;
  line-height: 3;

  @media (--small-viewport) {
    display: block;
  }
}
