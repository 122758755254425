@import '../../../styles/variables';

.default {

}

.dropdown {
  margin: 24px 0;

  @media (--tablet-portrait) {
    margin: 32px 0;
  }
}

.options {
  box-sizing: content-box;
  max-width: var(--inner-width);
  padding: 0 var(--gutter);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-offwhite);
  font-family: var(--font-family-sans);
  font-weight: 500;
  color: var(--color-gray);
  font-size: 12px;

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }

  select {
    background-color: var(--color-offwhite);
    color: var(--color-gray);
    margin-top: 0;
    font-size: 14px !important;
  }
}

.sort {
  align-items: center;
  display: flex;

  > div {
    display: flex;
    align-items: center;

    label {
      margin-right: 15px;
    }
  }
}
