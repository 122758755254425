@import '../../styles/variables';

.signup-prompt {
  margin-top: 1em;
  margin-bottom: .25em;
  font-family: var(--font-family-sans);
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
}

.signup-prompt a {
  color: var(--color-blue);

  &:hover, &:active, &:focus {
    color: var(--color-blue);
  }
}

.signup-prompt + .signup-prompt {
  margin-top: 0;
}

