@import '../../styles/variables';

.container {
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: 0.5em 0;
  padding: 0;
  text-align: left;

  label {
    font-size: 12px;
    color: var(--color-dark-gray);
    line-height: 3;
    letter-spacing: .7px;
  }
}

.textarea-wrapper {
  width: 100%;

  textarea {
    resize: none;
    border: 1px solid var(--gray-cf);
    border-radius: 2px;
    font-family: var(--font-family-sans);
    font-size: 12px;
    line-height: normal;
    padding: 1.5em;
    width: 100%;
    letter-spacing: .7px;

    &::placeholder {
      color: var(--gray-cf);
    }
  }
}

.container.wide {
  display: flex;
  margin: 1em 0;

  label {
    width: 35%;
    padding-right: 30px;
    text-align: right;
    letter-spacing: 1px;
  }

  .textarea-wrapper {
    margin-top: 0;
    width: 65%;

    textarea {
      height: 40px;
      line-height: 40px;
    }
  }
}

.container.narrow {
  flex: 1 1 50%;

  &:nth-child(odd) {
    padding-right: .5em;
  }

  &:nth-child(even) {
    padding-left: .5em;
  }
}

.container.short {
  margin: 0;

  label {
    line-height: 2;
  }
}
