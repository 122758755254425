@import '../../styles/variables';

.terms {
  box-sizing: content-box;
  color: var(--gray-85);
  font: normal 16px/2 Roboto;
  margin: 1em auto 3em;
  max-width: 700px;
  padding: 0 var(--gutter);

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }

  a {
    color: inherit;
  }
  ol, ul, p {
    color: var(--color-dark-gray);
    font: inherit;
    line-height: inherit;
  }

  p {
    font-size: 15px;
  }
}

.intro {
}

.intro h1 {
  margin: 64px 0 1em;
  font-size: 32px;
}

.sections {
  margin: 1em auto 1em;
}

.terms ol.sections,
.content ol {
  counter-reset: item;
  padding-left: 0;
}

.terms ol.sections ol,
.content ol ol {
  counter-reset: item;
  padding-left: 2em;
}

.terms {

  li {
    display: block;
    margin: 1em 0;
  }

  li::before {
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: bold;
  }

  ol ol li::before {
    font-weight: normal;
  }
}

.content .content li::before {
  content: normal;
}

.title {
  display: inline-block;
  font-weight: bold;
}

.content li li {
  display: list-item;

  &::before {
    content: normal;
  }
}
