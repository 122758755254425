@import '../../styles/variables';

.selected-tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  border: 1px solid var(--color-blue);
  border-radius: 2px;
  color: var(--color-blue);
  display: inline-block;
  margin: 5px 5px 0 0;
  padding: 0.5em 30px 0.5em 1em;
  position: relative;

  button {
    cursor: pointer;
    opacity: 0.5;
    font-size: 20px;
    position: absolute;
    top: 46%;
    transform: translateY(-50%);
    background: transparent;
    border: none;

    &:hover {
      opacity: 1;
    }
  }
}
