@import '../../../styles/variables';

.container {
  box-sizing: content-box;
  margin: 0 auto;
  max-width: var(--inner-width);
  padding: var(--gutter);

  @media (--tablet-portrait) {
    padding: 0 var(--gutter);
  }

  @media (--tablet-landscape) {
    padding: 0 var(--gracious-gutter);
  }
}

button.mobile-button {
  display: none;
  color: var(--color-black);
  font-weight: bold;
  letter-spacing: .5px;
  appearance: none;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  background-color: var(--color-white);
  padding: 0;

  &.active {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
    color: var(--color-white);
  }
}

.default {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 24px -7.5px;
  padding: 0;

  @media (--tablet-portrait) {
    margin: 32px -7.5px;
  }

  > * {
    padding: 0 7.5px;
    width: 25%;
  }
}

.mobile-label {
  display: none;
  line-height: 3;

  @media (--small-viewport) {
    display: block;
  }
}

.mobile-header {
  display: none;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--border-color);

  button {
    width: 75px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: var(--font-color);
    appearance: none;
    display: block;
    border-radius: 2px;
    background-color: var(--color-white);
    padding: 0;
    border: 0;

    &.save-button {
      background-color: var(--color-blue);
      color: var(--color-white);
    }
  }

  span {
    font-family: var(--font-family-sans);
    font-size: 16px;
    font-weight: 500;
  }
}

.filter-formats {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 360px;
}

.filter-split {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.filter-column {
  &:nth-child(2) {

    @media (--small-viewport) {
      margin-bottom: 15px;
    }
  }

  & + .filter-column {
    @media (--tablet-portrait) {
      margin-left: 40px;
    }
  }
}

.year-column {
  @media (--tablet-portrait) {
    width: 134px;
  }
}

.school-column {
  min-height: 390px;
  margin-bottom: 10px;
  @media (--tablet-portrait) {
    width: 400px;
  }
}

label {
  font-size: 12px;
  font-family: var(--font-family-sans);
}

.dropdown {
  position: relative;

  select {
    margin: 8px 0 30px;
    color: var(--color-dark-gray);
  }
}

.degree-dropdown {
}

@media (--small-viewport) {

  .container {
    padding: var(--gutter);
  }

  button.mobile-button {
    display: block;
  }

  .default {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--color-white);
    z-index: 100;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding-top: 59px;
    padding-bottom: 0;

    > * {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }

    .mobile-header {
      padding: 0 15px;
    }

    &.active {
      display: block;
    }
  }

  .mobile-header {
    display: flex;
  }

  .filter-formats {
    width: 100%
  }

  .filter-split {
    flex-direction: column;
    width: 100%;
  }

  .filter-column {
    flex: 0 0 auto;
  }
}
