@import '../../styles/variables';

.container {
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--gray-ef);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @media (--tablet-landscape), (orientation: portrait) {
    position: fixed;
  }

  &.loggedIn {
    border-bottom-color: var(--gray-ef);
  }

  &.isCms {
    color: var(--color-white);
    background-color: var(--color-dark-gray);
  }

  &.isCms.loggedIn {
    border-bottom-color: var(--border-color-dark);
  }

  .explore-nav {
    display: flex;
  }
}

.inner {
  align-items: center;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;

  @media (--tablet-portrait) {
    height: var(--header-height-desktop);
  }
}

.tablet-search-btn {
  width: 24px;
  height: 24px;
  display: flex;

  button {
    width: 100%;
    height: 100%;
    background-image: url('../../images/search.png');
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.mobile-search-btn {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 80px;
  top: 25px;
  z-index: 2;

  @media (--tablet-portrait), (orientation: portrait) {
    position: fixed;
    display: flex;
  }

  button {
    width: 100%;
    height: 100%;
    background-image: url('../../images/search.png');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
  }
}

/* Position and sizing of burger button */
:global(.bm-burger-button) {
  position: absolute;
  width: 26px;
  height: 22px;
  right: 36px;
  top: 25px;
  z-index: 2 !important;
  @media (--tablet-portrait), (orientation: portrait) {
    position: fixed;
  }
}


/* Color/shape of burger icon bars */
:global(.bm-burger-bars) {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
:global(.bm-cross-button) {
  height: 22px !important;
  width: 22px !important;
  margin: 21px 27px;
}

:global(.bm-menu-wrap) {
  top: 0 !important;
  z-index: 3 !important;
}

/* General sidebar styles */
:global(.bm-menu) {
  background: var(--color-white);
}

/* Morph shape necessary with bubble or elastic */
:global(.bm-morph-shape) {
  fill: #373a47;
}

/* Styling of overlay */
:global(.bm-overlay) {
  top: 0;
  left: 0;
  z-index: 2 !important;
}
