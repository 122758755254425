@import '../../styles/variables';

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form {
  width: 60%;

  button {
    float: right;
  }
}

.error {
  color: var(--color-red);
  display: inline-block;
  letter-spacing: .7px;
}

.inputs {
  margin: 30px 0;
}
