@import '../../styles/variables';

.container {
  color: var(--color-dark-gray);
  text-align: center;
  letter-spacing: .7px;
  margin-top: 2em;
  margin-bottom: -1em;
  font-size: 13px;

  a {
    color: var(--color-blue);

    &:hover, &:active, :focus {
      color: var(--color-blue);
    }
  }
}

.mobile-secondary-container {
  display: inline;

  @media (--small-viewport) {
    display: block;
  }
}