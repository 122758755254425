.graduation-list {
  padding-top: 2rem;
  .heading  {
    padding-left: 35%;
  }
  .controls {
    text-align: right;
    padding-right: 30px;
    .btn {
      font-size: 10px;
      padding: 0.5em 1.5em;
    }
  }

  .school-item {
    display: flex;
    margin: 1em 0;
    .controls {
      width: 35%;
    }
    .graduation {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
