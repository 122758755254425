@import '../../styles/variables';

.container {
  /* ported */
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  align-items: center;

  /* ported */
  label {
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--color-dark-gray);
    line-height: 2;
    margin-top: 1em;
  }

  /* ported */
  .error {
    color: var(--color-red);
  }

}

.input-wrapper {
  width: 100%;
  position: relative;

  /* ported */
  input {
    border: 1px solid var(--gray-cf);
    border-radius: 2px;
    font: inherit;
    font-size: 16px;
    height: 3em;
    padding: 1px 1em !important;
    width: 100%;
    letter-spacing: .7px;

    &::placeholder {
      color: var(--gray-cf);
    }

    &:invalid {
      box-shadow: none;
    }

    &.highlight {
      border: 1px solid var(--color-green);
    }
  }

  textarea {
    border: 1px solid var(--gray-cf);
    border-radius: 2px;
    font: inherit;
    font-size: 16px;
    padding: 0.5em 1em !important;
    width: 100%;
    letter-spacing: .7px;
    resize: none;

  &::placeholder {
     color: var(--gray-cf);
   }

  &:invalid {
     box-shadow: none;
   }

  &.highlight {
     border: 1px solid var(--color-green);
   }
  }
}

.container.wide {
  display: flex;
  margin: 1em 0;

  label {
    letter-spacing: 1px;
    margin-top: 0;
    padding-right: 30px;
    text-align: right;
    width: 35%;
  }

  .input-wrapper {
    margin-top: 0;
    width: 65%;

    input {
      height: 40px;
      line-height: 40px;
    }
  }
}

.container.narrow {
  flex: 1 1 50%;

  &:nth-child(odd) {
    padding-right: .5em;
  }

  &:nth-child(even) {
    padding-left: .5em;
  }
}

.container.short {
  margin: 0;

  label {
    line-height: 2;
  }
}

.container.location {
  display: flex;

  label {
    margin-right: 1em;
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 2;
  line-height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding: 0 16px;
  background: white;
  width: 100%;
  border: 1px solid var(--gray-cf);
  border-top: none;
}
