@import '../../styles/variables';

.logo {
  color: inherit;
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 29px;
  flex-basis: var(--gracious-gutter);
  text-align: center;
  text-decoration: none;
}
