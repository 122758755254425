@import '../../styles/variables';

.container {
  display: flex;
  flex-wrap: wrap;
}

.filter {
  background-color: var(--color-blue);
  color: white;
  display: inline-block;
  font-size: 13px;
  margin: .2em .2em .2em 0;
  padding: .5em 2.75em .5em 1em;
  position: relative;
  border-radius: 2px;
}

button.remove-button {
  position: absolute;
  border-radius: 10px;
  height: 18px;
  width: 18px;
  color: transparent;
  right: .75em;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-white);
  opacity: 0.9;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 1;
  }

  &:after, &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--color-blue);
    height: 1px;
    width: 10px;
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
}
