@import '../../styles/variables';

.section {
  padding: 80px 10%;
  display: flex;

  &:nth-child(even) {
    background-color: var(--color-offwhite);
    
    .details-container {
      order: 2;
      padding-left: 5%;
    }

    .image-container {
      padding-right: 5%;
    }
  }

  .image-container {
    max-width: 50%;
    padding-left: 5%;

    img {
      max-height: 50vh;
      width: 100%;
      object-fit: contain;
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-right: 5%;

    h2 {
      font-size: 32px;
      line-height: 1.1;
      margin: 0 0 .6em;
      color: var(--font-color);
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: var(--font-color);
      margin: 0;
    }

    p + p {
      margin: 40px auto 0;
      max-width: 100%;
    }

    .button {
      margin-top: 44px;
      background: transparent;
    }
  }



  @media (--small-viewport) {
    flex-direction: column; 
    padding: 50px 10%;

    .details-container {
      order: 2;
      padding: 0;
    }

    .image-container {
      padding: 0 0 10% !important;
      margin: auto;
      max-width: 80%;
    }

    &:nth-child(even) {
      .details-container {
        padding: 0;
      }
    }
  }
}

.header-link {
  text-decoration: none;
}
