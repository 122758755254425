/* Ported */
.btn {
  font-family: var(--font-family-sans);
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1.25px;
  padding: 1em 3em;
  text-decoration: none;
  border-radius: 2px;
  border: none;
  transition: opacity .2s ease;
  cursor: pointer;

  &[disabled] {
    background-color: var(--border-color);
  }

  &:hover, &:active, &:focus {
    opacity: .6;

  }
}

/* Ported */
.btn-blue {
  background-color: var(--color-blue);
  color: var(--color-white);
  border: 1px solid var(--color-blue);
}

.btn-white {
  background-color: var(--color-white);
  color: var(--color-dark-gray);
  border: 1px solid var(--color-dark-gray);
}

.btn-white-light {
  background-color: var(--color-white);
  color: var(--color-dark-gray);
  border: 1px solid var(--gray-cf);
}

.btn-gray {
  background-color: var(--color-gray);
  color: var(--color-white);
  border: 1px solid var(--color-gray);
}

.btn-dark-gray {
  background-color: var(--color-dark-gray);
  border: 1px solid var(--color-dark-gray);
  color: var(--color-white);
}

/* Ported */
.btn-full-width {
  width: 100%;
}
